import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Tabs} from 'antd';
import {API_URL} from "../../constants";
import {SearchContextConsumer} from "../../contexts/SearchContext";
import {withRouter} from "react-router";
import SearchComponent from "./SearchComponent";
import {QueryFromFilters} from "./SearchFilters/QueryFromFilters";
import {QueryWithTab, TabFromQuery} from "../Misc/Query";
import {SearchFiltersFromUrl} from "./SearchFilters/SearchFiltersFromUrl";
import {Conditional} from "../Misc/Conditional";
import {HierarchiesSearch} from "./HierarchiesGlobalSearch";
import {AuthContext} from "../../contexts/AuthContext";
import {AnimatedAuthUser} from "../../Utilities";


function SearchTabs({location, history, drawings, hierarchies}) {
    const [activeKey, setActiveKey] = useState("unknown");

    useEffect(() => {
        setActiveKey(new TabFromQuery(location.search).getValue());
    }, [location]);

    const onChangeTab = useCallback((key) => {
        const filters = new SearchFiltersFromUrl(location.search).getArray();

        history.push({
            pathname: location.pathname,
            search: new QueryWithTab(new QueryFromFilters(filters).toString(), key).toString()
        });
    }, [history, location]);

    return (
        <Tabs activeKey={activeKey} onChange={onChangeTab}>
            <Tabs.TabPane tab="Drawings" key="drawings">
                <Conditional
                    cond={() => activeKey === 'drawings'}
                    render={() => drawings}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Hierarchies" key="hierarchies">
                <Conditional
                    cond={() => activeKey === 'hierarchies'}
                    render={() => hierarchies}
                />
            </Tabs.TabPane>
        </Tabs>
    );
}


function UserViewSearchInner({context, location, history}) {
    const authContext = useContext(AuthContext);
    const user = new AnimatedAuthUser(authContext.user);

    const onSearch = useCallback((value, page) => context.setQuery(value), [context]);

    const drawings = (
        <SearchComponent
            fetchOptionsUrl={API_URL + `/user_view/search_results/possible_search_option_values`}
            url={API_URL + `/user_view/search_results`}
            onSearch={onSearch}
        />
    );

    if (user.canSearchForHierarchies()) {
        const hierarchies = (
            <HierarchiesSearch
                context={context}
                location={location}
                history={history}
                onSearch={onSearch}
            />
        );

        return (
            <SearchTabs
                location={location}
                history={history}
                drawings={drawings}
                hierarchies={hierarchies}
            />
        );
    } else {
        return drawings;
    }
}

const UserViewSearchInnerWithRouter = withRouter(UserViewSearchInner);

function UserViewSearch() {
  return (
      <SearchContextConsumer>
          {
            context => (
                <UserViewSearchInnerWithRouter context={context}/>
            )
          }
      </SearchContextConsumer>
  )
}

export default UserViewSearch;
