import React, {useEffect, useState} from "react";
import UserViewService from "../../../services/UserViewService";
import {Divider, Form, Input, message, Modal, Spin} from "antd";
import {RemoteFiltersEditor} from "../SearchFilters/RemoteFiltersEditor";
import {RequestBodyFromFilters} from "../SearchFilters/RequestBodyFromFilters";
import axios from "axios";
import {API_URL} from "../../../constants";
import {authHeader, empty} from "../../../Utilities";

export function BulkUpdateAttributesModal({fetchOptionsUrl, visible, onVisibilityChange}) {
    const [form] = Form.useForm();
    const [filterSchemas, setFilterSchemas] = useState([]);
    const [filters, setFilters] = useState([]);
    const [spinning, setSpinning] = useState(false);

    useEffect(() => {
        UserViewService.fetchSearchFiltersSchemas(setFilterSchemas);
    }, []);

    const closeModal = () => {
        onVisibilityChange(false);
        setFilters([]);
        form.resetFields();
    }

    const submit = () => {
        setSpinning(true);
        form.validateFields().then(_ => {
            const dataToSubmit = {
                ...form.getFieldsValue(),
                filters: new RequestBodyFromFilters(filters).getBody()
            }
            axios.post(
                API_URL + `/user_view/bulk_update_attributes`,
                dataToSubmit,
                {headers: authHeader()}
            ).then(({data}) => {
                const taskId = data.task_id;
                const getProgress = () => {
                    axios.get(
                        `${API_URL}/user_view/bulk_update_attributes/progress/${taskId}`,
                        {headers: authHeader()}
                    ).then(response => {
                        const progress = response.data;
                        if (progress == null || (!progress.error && progress.completed !== true)) {
                            setTimeout(() => getProgress(), 2000);
                        } else {
                            if (progress?.error) {
                                message.error(progress.error);
                            } else {
                                message.success("Updated");
                                closeModal();
                            }
                            setSpinning(false);
                        }
                    })
                }
                getProgress();
            }).catch(_ => {
                message.error("Error");
                setSpinning(false);
            });
        }).catch(empty);
    }

    const rules = [{required: true, message: 'Value can not be empty'}];

    return (
        <Modal
            visible={visible}
            onCancel={closeModal}
            onOk={submit}
            okText={"Run Update"}
            okButtonProps={{
                id: 'run-bulk-update'
            }}
            closable={false}
            destroyOnClose
            modalRender={node => (
                <Spin spinning={spinning}>
                    {node}
                </Spin>
            )}
        >
            <RemoteFiltersEditor
                fetchOptionsUrl={fetchOptionsUrl}
                filterSchemas={filterSchemas}
                filters={filters}
                onFiltersChanged={x => setFilters([...x])}
            />
            <Divider/>
            <Form form={form} initialValues={{attr_name: '', attr_value: ''}}>
                <Form.Item label={"Attribute Name"} name={"attr_name"} required rules={rules}>
                    <Input id="attr-name-input"/>
                </Form.Item>
                <Form.Item label={"Attribute Value"} name={"attr_value"} required rules={rules}>
                    <Input id="attr-value-input"/>
                </Form.Item>
            </Form>
        </Modal>
    );
}
